import React, { useEffect, useState, useLayoutEffect } from "react"
import AboutLogo from "../images/About_Logo.svg"
import ParadiseLogo from "../images/prd_logo_no_bg.svg"
import AboutCom from "../images/About_Com.svg"
import { Box, CircularProgress } from "@mui/material"
import { Link } from "gatsby"
import PortableText from "../components/PortableText"
import { Seo } from "../components/seo"
import { graphql } from "gatsby"

const About = ({ data }) => {
  const fixedNames = data.sanityAboutPage.names
  const text = data.sanityAboutPage._rawText
  const [names, setNames] = useState([])
  const [name, setName] = useState("")
  const [show, setShow] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [width, setWidth] = useState("0px")
  const ref = React.useRef()
  const ref2 = React.useRef()
  const submitName = async () => {
    const url = process.env.GATSBY_BACKEND + "/users"
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ name: name }),
    })
    getData()
  }
  const getData = async () => {
    const url = process.env.GATSBY_BACKEND + "/users"
    const res = await fetch(url)
    const data = await res.json()
    if (data.data) setNames(data.data.map((item) => item.name.toUpperCase()))
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    submitName()
    setSubmitting(true)
    setTimeout(() => {
      setName("")
      setSubmitting(false)
    }, 2000)
  }
  const whitespaceToNbsp = (str) => {
    return str.split(/(\s+)/).map((item, i) => {
      if (item[0] === " ") {
        return (
          <>
            {item.split("").map((c, index) => (
              <span key={index}>&nbsp;</span>
            ))}
          </>
        )
      }
      return <span key={"key" + i}>{item}</span>
    })
  }
  useEffect(() => {
    getData()
  }, [])
  useLayoutEffect(() => {
    if (names.length && ref?.current?.scrollWidth < 201)
      setWidth(ref.current.scrollWidth)
  }, [name, names.length])

  useEffect(() => {
    const animatedText = names.join(" ")
    let delay = 0
    function updateText() {
      ref2.current.innerHTML = animatedText
        .split("")
        .map((letter) => {
          if (letter === " ")
            return `<span class="letter space">` + letter + `</span>`
          return `<span class="letter">` + letter + `</span>`
        })
        .join("")

      Array.from(ref2.current.children).forEach((span, index) => {
        setTimeout(() => {
          span.classList.add("wavy")
        }, index * 60 + delay)
      })
    }

    if (names.length) updateText()
  }, [names, names.length])
  if (!names.length)
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          flexGrow: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100%",
          flexDirection: "column",
          backgroundColor: "#F85139",
        }}
      >
        <CircularProgress style={{ color: "yellow" }} size={45} />
      </div>
    )
  return (
    <div
      style={{
        flexGrow: 1,
        minHeight: 0,
        display: "flex",
        flexDirection: "column",
        minHeight: "100%",
        backgroundColor: "#F85139",
      }}
    >
      <Seo title="About Us" />
      <div
        style={{
          padding: "30px 0",
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
        }}
      >
        <Box display="flex" flexWrap="wrap">
          <Link
            to="/shop"
            style={{
              display: "block",
              maxWidth: 600,
              width: "100%",
              marginLeft: 30,
              marginRight: 75,
            }}
          >
            <ParadiseLogo />
          </Link>

          <Box
            className="top-right-text"
            maxWidth={1000}
            display="flex"
            style={{ fontSize: 14 }}
            // flexWrap="wrap"
          >
            <Box margin={2}>
              <PortableText blocks={text} />
            </Box>
            <Box margin={2}>
              <p>
                Con người thánh thiện, thức ăn có nước mắm, đường phố là đường
                đua, cái nóng đổ lửa. Không có cách nào khác để mô tả Sài Gòn,
                ngoài PARADISE.
              </p>
              <br />
              <p>
                Chúng tôi là những đứa con hoang đàng của mẹ Sài Gòn, không bị
                ngăn cách về tuổi tác, giới tính cũng như xuất thân. Được gắn
                kết với nhau bởi tình yêu chung dành cho thành phố và con người
                nơi đây. Với một trái tim đầy đam mê và phong cách riêng, chúng
                tôi đảm bảo mỗi tác phẩm nghệ thuật được thực hiện; chứa một
                mảnh của Paradise.
              </p>
            </Box>
          </Box>

          <AboutLogo
            style={{ position: "absolute", top: 15, right: 15 }}
            width={50}
          />
        </Box>
        <div style={{ position: "relative" }}>
          <button
            className={`transition-1 clickable insert-name ${
              !show ? "delay-1" : ""
            }`}
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              fontWeight: 900,
              transform: "translate(-50%, -50%)",
              color: "white",
              opacity: show ? 0 : 1,
              pointerEvents: show ? "none" : "all",
            }}
            onClick={() => setShow(true)}
          >
            [INSERT YOUR NAME]
          </button>
          <Box position="relative" height={30}>
            <button
              className="transition-1"
              style={{
                position: "absolute",
                right: 20,
                color: "white",
                opacity: !show ? 0 : 1,
                pointerEvents: !show ? "none" : "all",
              }}
              onClick={() => setShow(false)}
            >
              [CLOSE]
            </button>
          </Box>
          <div
            className={`enter-name-wrapper ${!show ? "delay-1" : ""}`}
            style={{ top: show ? 0 : 100, opacity: show ? 1 : 0 }}
          >
            I am{" "}
            <form
              style={{ display: "inline-block", position: "relative" }}
              onSubmit={handleSubmit}
            >
              <input
                disabled={submitting}
                className="autosize-name"
                value={name}
                style={{ width }}
                onChange={function (event) {
                  // event.target.value contains the new value
                  if (event.target.value.length < 12)
                    setName(event.target.value.replace(" ", ""))
                }}
              />
              <div
                ref={ref}
                className={
                  submitting ? "name-disappear overlay-text" : "overlay-text"
                }
              >
                {whitespaceToNbsp(name)}
              </div>
            </form>
            , <span>I am Paradise.</span>{" "}
          </div>
          <div
            className={`transition-1 ${show ? "delay-1" : ""}`}
            style={{
              textAlign: "center",
              color: "white",
              opacity: show ? 1 : 0,
            }}
          >
            *Type in your name. It's never too late to join us.
          </div>
        </div>

        <Box display="flex" overflow="hidden">
          <Box className="fixed-names">
            {fixedNames.map((item, index) => (
              <div key={"name" + index}>{item}</div>
            ))}
          </Box>
          <Box
            className="d-none d-md-block"
            flexGrow={1}
            padding={3}
            position="relative"
          >
            <div ref={ref2}></div>
            <AboutCom
              width={120}
              className="flashing-opacity"
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%,-50%)",
              }}
            />
          </Box>
        </Box>
      </div>
      {/* <AboutCom width={80} /> */}
    </div>
  )
}

export default About

export const query = graphql`
  query {
    sanityAboutPage {
      names
      _rawText
    }
  }
`
